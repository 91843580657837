import Countdown from 'react-countdown'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import Moment from 'moment-timezone'

import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShare: () => void
  isHardMode: boolean
  Statistics: boolean
  wordData: any
  showTimer: any
  date: any
}

export const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  isHardMode,
  Statistics,
  wordData,
  date,
  showTimer,
}: Props) => {
  if (Statistics == true) {
    if (gameStats.totalGames <= 0) {
      return (
        <BaseModal
          title={STATISTICS_TITLE}
          isOpen={isOpen}
          handleClose={handleClose}
        >
          <StatBar gameStats={gameStats} />
        </BaseModal>
      )
    }
    // console.log('wordData', wordData.shareable_link)

    return (
      <div>
        <BaseModal
          title=""
          addClass="tranformTop"
          isOpen={isOpen}
          handleClose={handleClose}
        >
          {wordData.answer_summery || wordData.answer_url ? (
            <div className="related-main">
              <h4
                className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
                style={{ marginBottom: '9px', marginTop: '9px' }}
              >
                Related Info
              </h4>
              <div>
                {wordData.answer_summery ? (
                  <div>
                    <p className='dark:text-gray-100'
                      style={{
                        // textAlign: 'justify',
                        textAlignLast: 'center',
                        lineHeight: '1.3',
                        wordBreak: 'break-word',
                      }}
                    >
                      {wordData.answer_summery}
                    </p>
                    <br></br>
                  </div>
                ) : (
                  ''
                )}

                {wordData.answer_url ? (
                  <div>
                    <p
                      style={{
                        // textAlign: 'justify',
                        textAlignLast: 'center',
                      }}
                    >
                      <a
                        href={wordData.answer_url}
                        className="link-color dark:text-gray-100"
                        target="_blank"
                        style={{
                          border: ' none',
                          outline: 'none',
                          wordBreak: 'break-word',
                        }}
                      >
                        {wordData.answer_title}
                      </a>
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )}

          <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
            {STATISTICS_TITLE}
          </h4>
          <StatBar gameStats={gameStats} />

          <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
            {GUESS_DISTRIBUTION_TEXT}
          </h4>

          <Histogram gameStats={gameStats} />
          {(isGameLost || isGameWon) && (
            <div>
              <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
                <div>
                  <h5>{NEW_WORD_TEXT}</h5>
                  <Countdown
                    className="text-lg font-medium text-gray-900 dark:text-gray-100"
                    date={tomorrow}
                    daysInHours={true}
                  />
                </div>
                <button
                  type="button"
                  className="mt-2 bg-green-500 w-full rounded-md  shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700  sm:text-sm"
                  onClick={() => {
                    shareStatus(
                      guesses,
                      isGameLost,
                      isHardMode,
                      wordData.url,
                      date,
                      wordData.shareable_link
                    )
                    handleShare()
                  }}
                >
                  {SHARE_TEXT}
                </button>

                {/* <button
                  type="button"
                  className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => {
                    shareStatus(guesses, isGameLost, isHardMode)
                    handleShare()
                  }}
                >
                  {SHARE_TEXT}
                </button> */}
              </div>
            </div>
          )}
        </BaseModal>
      </div>
    )
  } else {
    return (
      <BaseModal title="" isOpen={isOpen} handleClose={handleClose}>
        <div>
          <p>No word today. See you tomorrow!</p>
          {/* {showTimer ? (
            <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
              <h5>{NEW_WORD_TEXT}</h5>
              <Countdown
                className="text-lg font-medium text-gray-900 dark:text-gray-100"
                date={new Date(`${wordData.run_date} 00:00:00`).valueOf()}
                daysInHours={true}
              />
            </div>
          ) : (
            ''
          )} */}
          {/* <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              shareStatus(guesses, isGameLost, isHardMode)
              handleShare()
            }}
          >
            {SHARE_TEXT}
          </button> */}
        </div>
      </BaseModal>
    )
  }
}
