import {
  InformationCircleIcon,
  ChartBarIcon,
  CogIcon,
} from '@heroicons/react/outline'
import { useState, useEffect, useLayoutEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  ALERT_TIME_MS,
  REVEAL_TIME_MS,
  GAME_LOST_INFO_DELAY,
  ReactGaKey,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  getWordOfDay,
  solution as sl,
  findFirstUnusedReveal,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
} from './lib/localStorage'

import './App.css'
import { Oval } from 'react-loader-spinner'
import ReactGA from 'react-ga'

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches
  const params = useParams()
  const API_URL = process.env.REACT_APP_API_URL
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [currentGuess, setCurrentGuess] = useState('')
  const [currentDate, setCurrentDate] = useState('')

  const [wordData, setWordData] = useState({
    id: '',
    word: '',
    run_date: '',
    answer_summery: '',
    answer_url: '',
    logo: '',
  })
  const [solution, setSolution] = useState(sl)
  const [Statistics, setStatistics] = useState(true)
  const [reloadPage, setReloadPage] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [openError, setOpenError] = useState(false)

  const [playPuzzle, setplayPuzzle] = useState(true)
  const [char, setChar] = useState(true)

  const [disableEnterBtn, setDisableEnterBtn] = useState(false)

  const [showTimer, setShowTimer] = useState(false)
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isShowLoader, setShowLoader] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [isHardModeAlertOpen, setIsHardModeAlertOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [message, setMessage] = useState('')
  const [ClearStorage, setClearStorage] = useState(false)

  const [isGameLost, setIsGameLost] = useState(false)

  function evaluate(solution: any, loaded: any) {
    const gameWasWon = loaded.guesses.includes(solution.toUpperCase())

    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (!gameWasWon && loaded.guesses.length == MAX_CHALLENGES) {
      setIsGameLost(true)
      // setGameLost(true)
    }
  }
  useEffect(() => {
    ReactGA.initialize(ReactGaKey)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    // console.log('solutions', wordData)
    if (loaded.guesses.length) {
      evaluate(solution, loaded)
    }

    return loaded.guesses
  })

  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [isRevealing, setIsRevealing] = useState(false)
  const [gameLost, setGameLost] = useState(false)

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  const [isMissingPreviousLetters, setIsMissingPreviousLetters] =
    useState(false)
  const [missingLetterMessage, setIsMissingLetterMessage] = useState('')

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }

    // alert(props.match.params)
  }, [isDarkMode, isHighContrastMode])

  useEffect(() => {
    if (params.company) {
      // solution = 'v'
      setShowLoader(true)
      axios
        .get(API_URL + `get-company-url?url=${params.company}`)
        .then((response) => {
          // console.log(response);
          setplayPuzzle(response.data.puzzle)
          if (response.data.success === false) {
            if (response.data.undefined === true) {
              setIsSuccess(false)
            } else {
              setIsSuccess(true)
            }
            setMessage(response.data.message)
          } else {
            if (response.data.puzzle === true) {
              setWordData(response.data.data)
            } else {
              if (typeof response.data.data !== 'undefined') {
                setWordData(response.data.data)
              } else {
                setMessage(response.data.message)
              }
              setStatistics(false)
             
              setIsStatsModalOpen(true)
            }
            if (response.data.puzzle == true) {
              const loaded = loadGameStateFromLocalStorage()
              evaluate(response.data.data.word, loaded)

              // if (response.data.model) {
              //   setIsInfoModalOpen(true)
              // } else {
              //   setIsInfoModalOpen(false)
              // }
              localStorage.removeItem('isReloadPage')
              if (localStorage.getItem('run_date')) {
                if (
                  typeof response.data.data.run_date != 'undefined' &&
                  response.data.data.run_date
                ) {
                  let puzzle_id: string =
                    localStorage.getItem('puzzle_id') || ''
                  puzzle_id = atob(puzzle_id)
                  // console.log('puzzle_id=>', puzzle_id)
                  setClearStorage(true)

                  if (
                    localStorage.getItem('run_date') !=
                      response.data.data.run_date ||
                    puzzle_id != response.data.data.id
                  ) {
                    saveGameStateToLocalStorage({ guesses: [], solution: '' })
                    window.location.href = `/${params.company}`
                  }
                }
              }
              // setSolution(response.data.word)
            } else {
              saveGameStateToLocalStorage({ guesses: [], solution: '' })
              setIsGameWon(false)
              setIsGameLost(false)
              if (!localStorage.getItem('isReloadPage')) {
                window.location.href = `/${params.company}`
                localStorage.setItem('isReloadPage', 'true')
              }
            }

            setIsSuccess(true)
          }

          if (response.data.puzzle == false) {
            setSuccessAlert('')
            setIsGameWon(false)
            setIsGameLost(false)
          }
          setShowTimer(response.data.timer)
          setShowLoader(false)
          setChar(false)
        })
    }
    // console.log(params)
  }, [])

  useEffect(() => {
    if (localStorage.getItem('isReload') == 'done') {
      if (!localStorage.getItem('inModel')) {
        setIsInfoModalOpen(true)
        localStorage.setItem('inModel', 'done')
      }
    }
  }, [])

  const onChar = (value: string) => {
    if (
      playPuzzle == true &&
      isShowLoader == false &&
      isSuccess == true &&
      char == false
    ) {
      if (
        currentGuess.length < MAX_WORD_LENGTH &&
        guesses.length < MAX_CHALLENGES &&
        !isGameWon
      ) {
        setCurrentGuess(`${currentGuess}${value}`)
      }
    }
  }
  // useEffect(() => {

  //   if (localStorage.getItem('run_date')) {
  //     // alert(localStorage.getItem('run_date'))

  //     if (localStorage.getItem('run_date') != todayDate) {
  //       saveGameStateToLocalStorage({ guesses: [], solution: '' })
  //     }
  //   }
  // }, [wordData])

  useEffect(() => {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    var todayDate = yyyy + '-' + mm + '-' + dd
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(today)
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(today)
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(today)

    var currentMonthAndDate = mo + ' ' + da
    setCurrentDate(currentMonthAndDate)

    if (typeof wordData != 'undefined') {
      if (wordData.word) {
        getWordOfDay(wordData.word)

        // localStorage.setItem('word', wordData.word)
      }
      if (wordData.run_date) {
        localStorage.setItem('run_date', wordData.run_date)
      }
       if (ClearStorage) { //this comment by wahab on 07/09/2023
        if (wordData.id) {
          localStorage.setItem('puzzle_id', btoa(wordData.id))
        }
      }
    }
    // console.log('data=>', wordData)

    //
    // console.log('two')

    // console.log(wordData.run_date)
  }, [wordData, ClearStorage])
  useEffect(() => {
    if (localStorage.getItem('isReload') != 'done') {
      localStorage.setItem('isReload', 'done')
      window.location.href = `/${params.company}`
    }
  }, [wordData])
  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      setIsHardModeAlertOpen(true)
      return setTimeout(() => {
        setIsHardModeAlertOpen(false)
      }, ALERT_TIME_MS)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  useEffect(() => {
    // console.log('save localstorage', solution)
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    // console.log(isGameWon)

    if (isGameWon) {
      setTimeout(() => {
        setSuccessAlert(
          WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
        )
        setTimeout(() => {
          setSuccessAlert('')
          setIsStatsModalOpen(true)
        }, ALERT_TIME_MS)
      }, REVEAL_TIME_MS * MAX_WORD_LENGTH)
    }
    if (isGameLost) {
      // console.log('solutions=>', solution)

      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, GAME_LOST_INFO_DELAY)
    }
    if (isGameWon == false && isGameLost == false) {
      setSuccessAlert('')
    }
  }, [isGameWon, isGameLost])

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (playPuzzle === false) {
      return false
    }
    if (isGameWon || isGameLost) {
      return
    }
    if (currentGuess == '') {
      setIsNotEnoughLetters(true)
      setCurrentRowClass('jiggle')
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
        setCurrentRowClass('')
      }, ALERT_TIME_MS)
      return false
    }

    // console.log('currentGuess' + currentGuess);
    const winningWord = isWinningWord(currentGuess)
    let responses = {}
    setDisableEnterBtn(true)
    axios
      .post(API_URL + `save-words`, {
        puzzle_id: wordData.id,
        attempt_word: currentGuess,
        number_of_attempt: guesses.length + 1,
        isWordSuccess: winningWord,
      })
      .then((resp) => {
        responses = resp
        if (resp.data.success == false) {
          setOpenError(true)
          setErrorMessage(resp.data.message)
          setDisableEnterBtn(false)
          return setTimeout(() => {
            setOpenError(false)
          }, ALERT_TIME_MS)
        } else {
          if (!(currentGuess.length === MAX_WORD_LENGTH)) {
            setIsNotEnoughLetters(true)
            setCurrentRowClass('jiggle')
            return setTimeout(() => {
              setIsNotEnoughLetters(false)
              setCurrentRowClass('')
            }, ALERT_TIME_MS)
          }

          // console.log(winningWord)

          // if (!isWordInWordList(currentGuess)) {
          //   setIsWordNotFoundAlertOpen(true)
          //   setCurrentRowClass('jiggle')
          //   return setTimeout(() => {
          //     setIsWordNotFoundAlertOpen(false)
          //     setCurrentRowClass('')
          //   }, ALERT_TIME_MS)
          // }

          // enforce hard mode - all guesses must contain all previously revealed letters
          if (isHardMode) {
            const firstMissingReveal = findFirstUnusedReveal(
              currentGuess,
              guesses
            )
            if (firstMissingReveal) {
              setIsMissingLetterMessage(firstMissingReveal)
              setIsMissingPreviousLetters(true)
              setCurrentRowClass('jiggle')
              return setTimeout(() => {
                setIsMissingPreviousLetters(false)
                setCurrentRowClass('')
              }, ALERT_TIME_MS)
            }
          }

          setIsRevealing(true)
          // turn this back off after all
          // chars have been revealed
          setTimeout(() => {
            setIsRevealing(false)
          }, REVEAL_TIME_MS * MAX_WORD_LENGTH)

          if (
            currentGuess.length === MAX_WORD_LENGTH &&
            guesses.length < MAX_CHALLENGES &&
            !isGameWon
          ) {
            setGuesses([...guesses, currentGuess])
            setCurrentGuess('')
            // alert('f')

            if (winningWord) {
              setStats(addStatsForCompletedGame(stats, guesses.length))
              return setIsGameWon(true)
            }

            if (guesses.length === MAX_CHALLENGES - 1) {
              setGameLost(true)
              setTimeout(function () {
                setStats(addStatsForCompletedGame(stats, guesses.length + 1))
                setIsGameLost(true)
              }, 3000)
            }
          }
        }
        setDisableEnterBtn(false)
      })
  }

  if (isSuccess == true) {
    return (
      <div className="fit-screen">
        {isShowLoader == true ? (
          <div className="loader-main">
            <Oval height="50" width="50" color="grey" ariaLabel="loading" />
          </div>
        ) : (
          ''
        )}
        <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div
            className="flex w-80 mx-auto items-center mb-8 mt-3 remove-margin"
            style={{ marginTop: '1rem', marginBottom: '1.5rem' }}
          >
            <h1
              className="text-xl ml-2.5 grow font-bold dark:text-white"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {wordData.logo ? (
                <img
                  src={BASE_URL + 'member-area/userimages/' + wordData.logo}
                  className="logo-style"
                />
              ) : (
                ''
              )}{' '}
              {currentDate}
            </h1>
            <InformationCircleIcon
              className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
              onClick={() => setIsInfoModalOpen(true)}
            />
            <ChartBarIcon
              className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
              onClick={() => setIsStatsModalOpen(true)}
            />
            <CogIcon
              className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
              onClick={() => setIsSettingsModalOpen(true)}
            />
          </div>
          <Grid
            guesses={guesses}
            currentGuess={currentGuess}
            isRevealing={isRevealing}
            currentRowClassName={currentRowClass}
          />
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            onDisable={disableEnterBtn}
            guesses={guesses}
            isRevealing={isRevealing}
          />
          <InfoModal
            isOpen={isInfoModalOpen}
            handleClose={() => setIsInfoModalOpen(false)}
          />
          <StatsModal
            isOpen={isStatsModalOpen}
            handleClose={() => setIsStatsModalOpen(false)}
            guesses={guesses}
            gameStats={stats}
            isGameLost={isGameLost}
            isGameWon={isGameWon}
            date={currentDate}
            Statistics={Statistics}
            wordData={wordData}
            showTimer={showTimer}
            handleShare={() => {
              setSuccessAlert(GAME_COPIED_MESSAGE)
              return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
            }}
            isHardMode={isHardMode}
          />
          <SettingsModal
            isOpen={isSettingsModalOpen}
            handleClose={() => setIsSettingsModalOpen(false)}
            isHardMode={isHardMode}
            handleHardMode={handleHardMode}
            isDarkMode={isDarkMode}
            handleDarkMode={handleDarkMode}
            isHardModeErrorModalOpen={isHardModeAlertOpen}
            isHighContrastMode={isHighContrastMode}
            handleHighContrastMode={handleHighContrastMode}
          />

          <Alert
            message={NOT_ENOUGH_LETTERS_MESSAGE}
            isOpen={isNotEnoughLetters}
          />

          <Alert message={errorMessage} isOpen={openError} />

          <Alert
            message={WORD_NOT_FOUND_MESSAGE}
            isOpen={isWordNotFoundAlertOpen}
          />
          <Alert
            message={missingLetterMessage}
            isOpen={isMissingPreviousLetters}
          />
          <Alert
            message={CORRECT_WORD_MESSAGE(wordData.word ? wordData.word : '')}
            isOpen={gameLost && !isRevealing}
            variant="winword"
          />
          <Alert
            message={successAlert}
            isOpen={successAlert !== ''}
            variant="winword"
            topMost={true}
          />
        </div>
        {/* <div className={playPuzzle == false ? 'overlay' : ''}></div> */}
      </div>
    )
  } else {
    return (
      <div>
        <section className="page_404">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 ">
                <img
                  src={`${process.env.PUBLIC_URL}/930-9306658_404-not-found.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default App
