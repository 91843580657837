import React from 'react'
import ReactDOM from 'react-dom'
function HomePageComponent() {
  return (
    <div style={{ marginTop: '150px' }}>
      <div style={{ textAlign: 'center' }}>
        {/* image */}
        <div className="image-center">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt=""
            style={{ width: '258px' }}
          />
        </div>
        {/* image */}
        {/* discription */}
        <p className="home-discription">
          Create your own Wordle-like word puzzles.
        </p>
        {/* discription */}
        <div className="image-center">
          <a href="/member-area/register" className="signBtn">
            <span className="sign-up-text">Sign up</span>
          </a>
        </div>
        <div style={{ marginTop: '22px' }}>
          <a href="/member-area/login" className="loginLink">
            <span>Log in</span>
          </a>
        </div>

        <div>
          <div className="bottom-text">
            <div>
              <a
                href="https://about.newsword.org"
                style={{ color: '#a7a7a7', textDecoration: 'underline' }}
              >
                About
              </a>
              &nbsp;&nbsp;<b>.</b>&nbsp;&nbsp;
              <a
                href="https://about.newsword.org/terms"
                style={{ color: '#a7a7a7', textDecoration: 'underline' }}
              >
                Terms
              </a>
              &nbsp;&nbsp;<b>.</b>&nbsp;&nbsp;
              <a
                href="https://about.newsword.org/privacy"
                style={{ color: '#a7a7a7', textDecoration: 'underline' }}
              >
                Privacy
              </a>
              &nbsp;&nbsp;
            </div>
            <br></br>© 2022 Newsword.org
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomePageComponent
